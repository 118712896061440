import Image from "#app/components/Image";
import { useDebounceEffect } from "#app/components/ImageUploader/useDebounceEffect";


import { cn } from "#app/components/utils";
import { prisma } from "#app/utils/db.server";
import { arrayToMapByKey, combineHeaders, invariantResponse } from "#app/utils/misc";
import { formatPrice } from "#app/utils/text-format";
import { Product } from "@prisma/client";
import { LoaderFunctionArgs } from "@remix-run/node";
import { json, useFetcher, useLoaderData } from "@remix-run/react"
import { Box, CircleX, DollarSign, Loader2, ShoppingCart } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import {action as updateCartItemAction} from '#app/routes/shopping-cart+/resources+/update-cart-item';
import { getOrCreateMenuShoppingCart, setMenuShoppingCartId } from "#app/utils/shopping-cart.server";
import { usePrevious } from "#app/hooks/usePrevious";
import { MenuButton } from "#app/components/ui/MenuButton";

import ChevronUpMenuButton from '#app/assets/icons/chevron-up-menu-button.svg?react';
import { ScrollArea } from "#app/components/ui/scroll-area";
import { NumberPicker } from "#app/components/number-picker";
import { getFlash } from "#app/utils/flash.server";

export async function loader({request, params}: LoaderFunctionArgs) {
    const companySlug = params.companySlug;
    invariantResponse(companySlug, 'Empresa '+ companySlug+' não encontrada');

    const organization = await prisma.organization.findFirst({
        select: {
            id: true,
            name: true,
            logo: {
                select: {
                    location: true
                }
            },
            slug: true
        },
        where: {
            slug: companySlug
        }
    });
    invariantResponse(organization, 'Empresa '+ companySlug+' não encontrada');
    const menuSlug = params.menuSlug;
    const menu = await prisma.menu.findFirst({
        select: {
            id: true,
            name: true,
            coverImage: {
                select: {
                    location: true
                }
            },
            categories: {
                select: {
                    id: true,
                    name: true,
                    products: {
                        select: {
                            id: true,
                            product: {
                                select: {
                                    id: true,
                                    title: true,
                                    active: true,
                                    price: true,
                                    hundredthPrice: true,
                                    minimumOrder: true,
                                    images: {
                                        select: {
                                            location: true
                                        }
                                    },
                                    description: true,
                                    inventoryTransactions: {
                                        select: {
                                            currentStock: true
                                        },
                                        orderBy: {
                                            timestamp: 'desc'
                                        },
                                        take: 1
                                    }
                                }
                            },
                            
                        },
                        where: {
                            product: {
                                deletedAt: {
                                    equals: null
                                }
                            }    
                        },
                        orderBy: {
                            order: 'asc'
                        }
                    },

                },
                orderBy: {
                    order: 'asc'
                },
                where: {
                    active: true,
                }
            },
            slug: true
        },
        where: {
            archived: false,
            ...(menuSlug ? {slug: menuSlug} : {main: true}),
            organizationId: organization.id
        }
    });

    invariantResponse(menu, 'Cardápio não encontrado');
    const shoppingCart = await getOrCreateMenuShoppingCart(request, menu.id)
    invariantResponse(shoppingCart, 'Carrinho não encontrado');

    const menuShoppingCartCookie = await setMenuShoppingCartId(request,menu.id, shoppingCart?.id ?? '');

    const {headers} = await getFlash(request);

    return json({organization, menu, shoppingCart}, {
        headers: combineHeaders({
            'Set-Cookie': menuShoppingCartCookie
        }, headers)
    })
}

export default function CompanyMenuPage() {
    const data = useLoaderData<typeof loader>();
    const productItemMap = arrayToMapByKey(data.shoppingCart.items, 'product.id');

    const organizationName = data.organization.name;
    const organizationLogoUrl = data.organization.logo?.location
    const categories = data.menu?.categories ?? null;

    const menuCoverUrl = data.menu?.coverImage?.location;

    const [isAtTop, setIsAtTop] = useState(false);
    const topRef = useRef<HTMLDivElement>(null);
    const categoriesContainerRef = useRef<Array<HTMLDivElement>>([]);
    const [visibleCategory,setVisibleCategory] = useState('');
    const [cartDetailsOpen, setCartDetailsOpen] = useState(false);

    const getTopObserver = useCallback(() => new IntersectionObserver(
        ([entry]) => {
          // Verifica se o elemento está no topo do viewport
          if (entry.boundingClientRect.top <= 0) {
            setIsAtTop(true);
          } else {
            setIsAtTop(false);
          }

          if(window.scrollY === 0) {
            setIsAtTop(false);
          }
        },
        {
          root: null, // Usa o viewport como root
          threshold: 0, // Dispara assim que o topo do elemento tocar o viewport
          rootMargin: "0px 0px -100% 0px", // ajusta para detectar o topo do viewport
        }
      ), []);

      useEffect(() => {
        const overflow = cartDetailsOpen && data.shoppingCart.items.length > 0 ? 'hidden' : 'auto';
        document.body.style.overflow = overflow;

        if(data.shoppingCart.items.length === 0) {
            setCartDetailsOpen(false);
        }
      }, [cartDetailsOpen, data.shoppingCart.items.length])

      useEffect(() => {
        const observer = getTopObserver();
        if (topRef.current) {
            observer.observe(topRef.current);
          }
      
          // Limpa o observer quando o componente desmonta
          return () => {
            if (topRef.current) {
              observer.unobserve(topRef.current);
            }
          };
      }, [getTopObserver]);

      useEffect(() => {
        if(visibleCategory) {
            scrollToElementWithinHorizontalContainer(
                document.getElementById('category-ribbon') ?? undefined,
                document.querySelector(`[data-category-key="${visibleCategory}"]`) ?? undefined
            )
        }
      }, [visibleCategory])
      
      const getCategoriesObserver = useCallback(() => new IntersectionObserver(
        (entries) => {

            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    setVisibleCategory(entry.target.id)
                }

            });

        },
        {
          root: null, // Usa o viewport como root
          threshold: 0.2, // Dispara assim que o topo do elemento tocar o viewport
          rootMargin: "0px 0px 0% 0px", // ajusta para detectar o topo do viewport
        }
      ), []);

      useEffect(() => {
        const observer = getCategoriesObserver();

        if(categoriesContainerRef.current) {
            categoriesContainerRef.current.forEach((section) => {
                if (section) observer.observe(section);
            });
        }
        
    
        return () => {
            if(categoriesContainerRef.current) {
                categoriesContainerRef.current.forEach((section) => {
                    if (section) observer.unobserve(section);
                });
            }
            
        };
      }, [getCategoriesObserver])

    return <div>
        <Image src={menuCoverUrl} alt={data.menu.name} className="h-[35dvh] object-center fixed top-0 left-0 w-full -z-10"/>
        <div 
            ref={topRef}
            className="mt-[25dvh] rounded-t-2xl border-t bg-white"
        >
                <div>
                    <div className={cn("flex flex-row items-center gap-x-2 px-4 py-4 pb-0")}>
                        <Image src={organizationLogoUrl} className="w-16 h-16 rounded-full" alt={`Logo ${organizationName}`}/>
                        <div>
                            <h1 className="font-semibold text-menu-primary">{organizationName}</h1>
                            {/* <a href={`/${data.organization.slug}/info`} className="underline text-sm text-menu-primary">Saiba mais</a> */}
                        </div>
                    </div>
                </div>
                <div className={cn({"bg-white fixed top-0 z-10 w-full border-b-2": isAtTop})}>
                    <div className={cn("w-full relative bg-purple-950/80 hidden", {
                        block: isAtTop
                    })}>
                        <Image src={menuCoverUrl} alt="Imagem do cardápio" className="w-full absolute top-0 left-0 -z-10 h-full"/>
                        <div className="px-4 py-2 flex flex-row items-center justify-between gap-x-4 text-white max-w-full overflow-hidden">
                            <div className="flex-grow truncate flex flex-row items-center gap-x-2">
                                <Image src={organizationLogoUrl} className="w-8 h-8 rounded-full border border-white/20" alt={`Logo ${organizationName}`}/>
                                <h1 className="font-medium truncate">{organizationName}</h1>
                            </div>
                            {/* <button>
                                <SearchIcon size={20} />
                            </button> */}
                            
                        </div>
                        
                        
                    </div>
                    {data.menu?.categories ? <CategoryRibbon categories={data.menu?.categories.map(category => category.name)} activeCategory={visibleCategory} /> : null}
                </div>

                {(() => {
                    if(!categories || categories.length === 0) {
                        return <h2>Cardápio sem categorias</h2>
                    }

                    return <div>{categories?.map((category,i) => {
                        return <div id={category.name} 

                        ref={(el) => {
                            
                            if(el) {
                                categoriesContainerRef.current[i] = el;    
                            }
                        }}  
                        
                        key={category.id} className="mb-12">
                            <h2 className="text-2xl text-menu-primary px-8 font-semibold mb-4">
                                {category.name}
                            </h2>
                            <div className={cn("grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 md:gap-x-8 gap-y-4 px-4", {
                                'md:mb-[100px]': category.products.length <= 2
                            })}>
                                {category.products.map(({product}) => {
                                    const currentStock = product.inventoryTransactions?.[0]?.currentStock;
                                    const item = productItemMap.get(product.id);
                                    const productImage = product.images?.[0]?.location
                                    return <ProductCard key={product.id} product={{...product, image: productImage}} currentStock={currentStock} menuId={data.menu.id} initialQuantity={item?.quantity} />
                                })}
                            </div>
                        </div>
                    })}</div>
                })()}
                
        </div>
        <div className="h-20"></div>
        {data.shoppingCart.items.length > 0 ? <>
            {<div 
                onClick={() => setCartDetailsOpen(false)}
                className={cn("w-full h-0 opacity-0 fixed bottom-0 bg-black/70 z-10 transition-opacity", {
                'h-full opacity-100': cartDetailsOpen
            })} />}
            <div className="fixed bottom-0 w-full z-20">
                <div className="bg-menu-bg-foreground px-4 py-6 flex flex-col rounded-t-3xl border-t z-20">

                    {<ScrollArea className={cn("w-full pt-4 h-0 transition-all", {
                            'h-[30vh] mb-3 border-b border-slate-300': cartDetailsOpen
                        })}>
                            <div className="flex flex-col mt-4 gap-y-6">
                            {(() => {  
                                return data.shoppingCart.items.map(item => {
                                    const displayPrice = item.quantity >= 100 && item.product.hundredthPrice ? item.product.hundredthPrice : item.product.price;
                                    return <CartDetailItem 
                                        key={item.id} 
                                        quantity={item.quantity} 
                                        menuId={data.menu.id} 
                                        price={displayPrice} 
                                        productId={item.product.id} 
                                        subtotal={item.subtotal} 
                                        title={item.product.title}

                                    />
                                })
                            })()}
                            </div>
                            <div className="h-20" />
                    </ScrollArea>}
                    <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/3">
                        <button 
                            onClick={() => setCartDetailsOpen(!cartDetailsOpen)}
                        className={cn("h-[48px] w-[48px] flex items-center justify-center border-8 border-gray-200 bg-white rounded-full transition-transform", {
                            'rotate-180': cartDetailsOpen
                        })}>
                            <ChevronUpMenuButton />
                        </button>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center ">
                        <div className="flex flex-row gap-x-3.5">
                            <div>
                                <span className="text-menu-text-light text-sm">Itens</span>
                                <p className="font-semibold">{data.shoppingCart.totals.quantity}</p>
                            </div>
                            <div>
                                <span className="text-menu-text-light text-sm">Total</span>
                                <p className="font-semibold">{formatPrice(data.shoppingCart.totals.amount)}</p>
                            </div>
                        </div>
                        <div>
                            <a href={`/${data.organization.slug}/${data.menu.slug}/checkout/cart`}>
                            <MenuButton icon="arrow-forward">Encomendar</MenuButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>: null}
    </div>
}

type CartDetailItemProps = {
    menuId: string;
    productId: string;
    quantity: number;
    title: string;
    price: number;
    subtotal: number;
}
const CartDetailItem = (item: CartDetailItemProps) => {
    const fetcher = useFetcher<typeof updateCartItemAction>();
    const isDeleting = fetcher.state === 'submitting';
    return <fetcher.Form method="POST" action="/shopping-cart/resources/update-cart-item" className="flex flex-row text-xs sm:text-sm gap-x-3.5 text-menu-text-light pr-2">
        <input name="quantity" type="hidden" value={0} />
        <input name="menuId" type="hidden" value={item.menuId} />
        <input name="productId" type="hidden" value={item.productId} />
        <span className="w-[30px] sm:w-[60px]">{item.quantity}x</span>
        <span className="flex-1">{item.title} ({formatPrice(item.price)} unid.)</span>
        <div className="w-[90px] sm:w-[120px] flex flex-row justify-between gap-x-2  items-start">
            <span className="text-menu-text">{formatPrice(item.subtotal)}</span>
            <button type="submit">
                {isDeleting ? <Loader2 size={16} /> : <CircleX size={16}/>}
            </button>
        </div>
    </fetcher.Form>
}


type ProductCardProps = {
    product: Pick<Product, 'id' | 'title' | 'minimumOrder' | 'price' | 'hundredthPrice' | 'description' > & {
        image?: string;
    };
    menuId: string;
    initialQuantity?: number; 
    currentStock?: number;
}
const ProductCard = ({product, menuId, initialQuantity, currentStock}: ProductCardProps) => {
    const formId = `form-product-${product.id}`;
    const fetcher = useFetcher<typeof updateCartItemAction>();
    const initialValue = initialQuantity ?? 0;
    const [quantity, setQuantity] = useState(initialValue);
    const oldQuantity = usePrevious(quantity) ?? quantity;

    const oldInitialQuantity = usePrevious(initialValue);

    const minimumOrder = currentStock ? Math.min(currentStock, product.minimumOrder) : product.minimumOrder;

    const details = [
        {
            label: 'Pedido mín',
            value: minimumOrder+' unid.',
            icon: <ShoppingCart size={18} strokeWidth={2} />
        },
        product?.hundredthPrice ? {
            label: 'Cento',
            value: formatPrice((product?.hundredthPrice ?? product.price ?? 0) * 100),
            icon: <DollarSign size={18} strokeWidth={2} />
        } : null, 
        // currentStock !== undefined && currentStock > 0 ?
        // {
        //     label: 'Estoque',
        //     value: currentStock + ' unid.',
        //     icon: <Box size={18} strokeWidth={2} />
        // } : null,
    ].filter(detail => Boolean(detail));


    useDebounceEffect(() => {
        const hasZeroed = oldQuantity > 0 && quantity === 0;
        const changedInitialValue = quantity > 0 && initialValue !== quantity;
        
        if(hasZeroed || changedInitialValue) {
            fetcher.submit(document.getElementById(formId) as HTMLFormElement)
        }

    }, 400, [quantity, oldQuantity]);

    useEffect(() => {
        if(initialValue === 0 && oldInitialQuantity && oldInitialQuantity > 0) {
            setQuantity(0)
        }
    }, [oldInitialQuantity, initialValue])
    
    const handleMinimumOrder = (value: number, setInternalValue?: (value: number) => void) => {

    
        if(value < minimumOrder) {
            if(confirm(`${product.title} tem encomenda mínima de ${minimumOrder}, deseja remover produto do carrinho?`)) {
                setQuantity(0);
                setInternalValue?.(0)
            } else {
                setQuantity(minimumOrder)
                setInternalValue?.(minimumOrder)
            }

            return;
        }

        setQuantity(value)
    }

    return <article className="p-4 pb-8 drop-shadow-4xl bg-white rounded-3xl">
        <Image src={product.image} aspectRatio="4/3" className="w-full rounded-3xl" alt="Logo"/>
        <h2 className="text-xl text-menu-primary font-semibold mt-4 mb-3">
            {product.title}
            {currentStock && currentStock > 0 &&  currentStock < 10 ? <p className="text-red-600 text-sm uppercase font-medium">Últimas {currentStock} unidades</p>: null}
        </h2>
        
        <p className="text-menu-text-light">
            {product.description}
        </p>
        <div className="mt-6 rounded-3xl bg-menu-bg-foreground px-6 py-3 flex flex-row gap-x-1 justify-between text-xs sm:text-sm">
            {details.map(detail => {
                return <div key={detail?.label} className="flex flex-col gap-x-1">
                    <div className="flex flex-row gap-x-1 items-center">
                        <h3 className="text-menu-primary font-bold">{detail?.label}:</h3>
                    </div>
                    <div className="flex flex-row items-center gap-x-1 text-brand-textLight">
                        <span className="hidden min-[340px]:block">{detail?.icon}</span> <p>{detail?.value}</p>
                    </div>
                </div>
            })}
        </div> 
        <fetcher.Form 
            id={formId} 
            method="POST" 
            action="/shopping-cart/resources/update-cart-item"
            className="mt-6 w-full justify-between flex flex-row gap-x-4 items-center"
        >
            <div>
                {product.hundredthPrice && quantity >= 100 ? <>
                    <p className="text-xs">De <s className="text-red-600">{formatPrice(product.price)}</s> unidade por:</p>
                    <p className="font-bold">{formatPrice(product.hundredthPrice)} Unidade</p>
                </>: <>
                    <p className="font-bold">{formatPrice(product.price)} Unidade</p>
                </>}
            </div>

            <input name="menuId" type="hidden" value={menuId} />
            <input name="productId" type="hidden" value={product.id} />
            <input name="quantity" type="hidden" value={quantity} />
            {(() => {
                if(currentStock !== undefined && currentStock <= 0) {
                    return <MenuButton icon='shopping-cart' disabled LeftIcon={() => null} className="pl-2 pr-4 py-1.5 bg-red-600 text-white"   onClick={() => alert('Produto esgotado')}>Esgotado</MenuButton>;
                }
                
                if(quantity === 0) {
                    return <MenuButton icon='plus' className="pl-2 pr-4 py-1.5"   onClick={() => {
                        setQuantity(minimumOrder)
                        if(window.fbq) {
                            window.fbq('track', 'AddToCart', {
                                content_name: product.title,
                                content_ids: [product.id],
                                content_type: 'product',
                                value: product.price / 100, 
                                currency: 'BRL'
                            });
                        }
                    }}>Adicionar</MenuButton>;
                }

                if(quantity > 0) {
                    return <div className="w-1/2 sm:w-1/4 md:w-1/2">
                            <NumberPicker
                                defaultValue={initialValue > 0 ? initialValue : minimumOrder} 
                                min={0} 
                                max={currentStock ? Math.min(currentStock, 99999) : 99999} 
                                step={1}
                                onChange={(val, {source, setInternalValue}) => {
                                    console.log("Novo valor:", {val, source})
                                    
                                    if(source !== 'input') {
                                        setQuantity(val);
                                        handleMinimumOrder(val, setInternalValue)
                                    }
                                    
                                    
                                }}
                                onBlur={(val, {setInternalValue}) => {
                                    console.log("Valor final ao sair:", val)
                                    handleMinimumOrder(val, setInternalValue)
                                }}
                                className="rounded-full"
                                buttonsClassName="rounded-full bg-menu-accent"
                            />  
                    </div>
                }
            })()}
            {/* {quantity === 0 ? <MenuButton icon='plus' className="pl-2 pr-4 py-1.5"   onClick={() => setQuantity(minimumOrder)}>Adicionar</MenuButton> : null}      
            {quantity > 0 ? <div className="w-1/2 sm:w-1/4 md:w-1/2">
                <NumberPicker
                        defaultValue={initialValue > 0 ? initialValue : minimumOrder} 
                        min={0} 
                        max={99999} 
                        step={1}
                        onChange={(val, {source, setInternalValue}) => {
                            console.log("Novo valor:", {val, source})
                            
                            if(source !== 'input') {
                                setQuantity(val);
                                handleMinimumOrder(val, setInternalValue)
                            }
                            
                            
                        }}
                        onBlur={(val, {setInternalValue}) => {
                            console.log("Valor final ao sair:", val)
                            handleMinimumOrder(val, setInternalValue)
                        }}
                        className="rounded-full"
                        buttonsClassName="rounded-full bg-menu-accent"
                    />  </div>: null} */}
        </fetcher.Form> 
    </article>
}

type CategoryRibbonProps = {
    categories: Array<string>;
    activeCategory?: string;
}
const CategoryRibbon = ({categories, activeCategory} : CategoryRibbonProps) => {    
    const currentCategory = activeCategory ?? categories[0];
    return <div id="category-ribbon" className="flex flex-row items-center gap-x-3 overflow-auto scrollbar-hide px-4 py-3 transition-all">
            {categories.map(category => {
                return <button onClick={() => {
                    scrollToElementWithinVerticalContainer(document.body, document.getElementById(category) ?? undefined, -30)

                }} data-category-key={category} key={category} className={cn(
                    "shrink-0 bg-menu-bg-foreground px-4 py-1 text-sm text-nowrap rounded-md font-light", 
                    {
                        'bg-menu-primary text-white': currentCategory === category
                    }
                )}>
                    {category}
                </button>
            })}
        </div>
}

function scrollToElementWithinHorizontalContainer(
    container?: HTMLElement,
    element?: HTMLElement | Element
  ) {
    if (container && element) {
      const containerLeft = container.getBoundingClientRect().left;
      const elementLeft = element.getBoundingClientRect().left;
      const elementWidth = element.getBoundingClientRect().width;
  
      const scrollPosition = elementLeft - containerLeft + container.scrollLeft - ((elementWidth / 2));
  
      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',

      });
    }
  }

  function scrollToElementWithinVerticalContainer(
    container: HTMLElement,
    element?: HTMLElement,
    offset?: number
  ) {
    if (container && element) {
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = element.getBoundingClientRect().top;
  
      const scrollPosition = elementTop - containerTop + container.scrollTop + (offset ?? 0);

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  }